import { IconsNames } from 'components/atoms';
import { PATHS } from 'router/config';

export enum EducationForm {
  online = 'Дистанционный курс',
  offline = 'Офлайн курс',
}

export enum CategoryNames {
  all = 'Все',
  // dayOffer = 'Предложение дня',
  hits = 'Хиты продаж',

  programming = 'Программирование',
  design = 'Дизайн',
  testing = 'Тестирование',
  management = 'Менеджмент',
  gameDev = 'GameDev',
  analysis = 'Анализ данных',
}

export enum Courses {
  fullstack_csharp = 'csharp',
  game_dev = 'game_dev',
  motion = 'motion',
  business_analyst = 'ba',
  designer = 'designer',
  unreal_engine = 'unreal_engine',
  python = 'python',
  java = 'java',
  system_analyst = 'system_analyst',
  devops = 'dev-ops',
  frontend = 'frontend',
  one_c = 'one_c',
  uxui = 'uxui',
  unity = 'unity',
  ios = 'ios',
  qa_python = 'qa_python',
  qa_java = 'qa_java',
  project_manager = 'pm',
  game_design = 'gamedesigner',
  hr = 'hr',
  manual_qa = 'manual_qa',
  sre = 'sre',
  flutter = 'flutter',
  android = 'android',
  person = 'person',
  qa_csharp = 'qa_csharp',
  go = 'go',
  data_engineer = 'data_engineer',
  qa_js = 'qa_js',
  web = 'web',
  ds = 'ds',
  business_intelligence = 'business_intelligence',
  ml = 'ml',
  product_analyst = 'product_analyst',
  cyber = 'cyber',
}

export const CoursesPathToKey = {
  'fullstack_csharp':Courses.fullstack_csharp,
  'game_dev':Courses.game_dev,
  'motion':Courses.motion,
  'business_analyst':Courses.business_analyst,
  'designer':Courses.designer,
  'unreal_engine':Courses.unreal_engine,
  'python':Courses.python,
  'java':Courses.java,
  'system_analyst':Courses.system_analyst,
  'devops':Courses.devops,
  'frontend':Courses.frontend,
  'one_c':Courses.one_c,
  'uxui':Courses.uxui,
  'unity':Courses.unity,
  'ios':Courses.ios,
  'qa_python':Courses.qa_python,
  'qa_java':Courses.qa_java,
  'project_manager':Courses.project_manager,
  'game_design':Courses.game_design,
  'hr':Courses.hr,
  'manual_qa':Courses.manual_qa,
  'sre':Courses.sre,
  'flutter':Courses.flutter,
  'android':Courses.android,
  'person':Courses.person,
  'qa_csharp':Courses.qa_csharp,
  'go':Courses.go,
  'data_engineer':Courses.data_engineer,
  'qa_js':Courses.qa_js,
  'web':Courses.web,
  'ds':Courses.ds,
  'business_intelligence':Courses.business_intelligence,
  'ml':Courses.ml,
  'product_analyst':Courses.product_analyst,
  'cyber':Courses.cyber,
}

export const DayOfferCourses: Courses[] = [];

export const HitCourses: Courses[] = [Courses.frontend, Courses.python, Courses.uxui, Courses.devops,];

export enum Technologies {
  python = 'python',
  java = 'java',
  javaScript = 'javaScript',
  html = 'html',
  css = 'css',
  flask = 'flask',
  gitLab = 'gitLab',
  pyCharm = 'pyCharm',
  cPlusPlus = 'cPlusPlus',
  fastApi = 'fastApi',
}

export enum TechnologiesNames {
  python = 'Python',
  java = 'Java',
  javaScript = 'JavaScript',
  html = 'HTML',
  css = 'CSS',
  flask = 'Flask',
  gitLab = 'GitLab',
  pyCharm = 'PyCharm',
  cPlusPlus = 'C++',
  fastApi = 'FastApi',
}

export interface ICourse {
  key: Courses;
  categoryName: CategoryNames;
  courseName: string;
  discountPrice: string;
  oldPrice: string;
  perMonth: string;
  oldPerMonth: string;
  img: IconsNames;
  link: string;
  educationFrom: EducationForm;
  duration: string;
  closestStart: string;
  youWillLearn: string;
  futureKnowledge: string;
  // technologies: Technologies[];
  futureSalaryTitle: string;
  salary: string;
  programText: string;
  academyHours: string;
  lessons: string[];
  benefitIcon: IconsNames;
  discountOffer?: string;
}

export const AllCourses: ICourse[] = [
  {
    key: Courses.project_manager,
    categoryName: CategoryNames.management,
    courseName: 'Project Manager в IT',
    discountPrice: '70 000 ₽',
    oldPrice: '95 000 ₽',
    perMonth: '3888',
    oldPerMonth: '5277',
    img: IconsNames.cs_project,
    link: PATHS.project_manager,
    educationFrom: EducationForm.online,
    duration: '4 месяца',
    closestStart: 'Ближайшие старты - 14 ноября, 5 декабря',
    youWillLearn: 'С нуля до трудоустройства: управление проектами за 4 месяца.',
    futureKnowledge: 'Курсы Project Manager в IT позволят вам начать работу в качестве менеджера на любом проекте в компании. Современный руководитель IT-проекта знает не только техники управления проектами, но и понимает, как работают актуальные технологии. Эти знания позволяют ему находить общий язык с командой, а также грамотно планировать выполнение проекта.    Уникальность курса состоит в том, что он содержит не только золотые правила и техники из PMBoK и лучшие практики из Agile, но и технические основы коммерческой разработки. Также курс включает в себя огромный пласт информации о том, как вести коммуникацию, используя лучшие техники ведения переговоров. В итоге данный продукт представляет собой многогранный свод знаний, который позволит вам построить успешную карьеру в IT в качестве Project Manager.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Project Manager в IT',
    salary: '75 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '136 академических часов',
    lessons: [
      '1. Об IT в целом. Основы Computer Science',
      '2. Project Management в IT. Основы управления проектом',
      '3. Управление качеством проекта: риски, изменения, прогресс',
      '4. Гибкие методологии разработки ПО',
      '5. Управление командой. Основы коммуникации',
      '6. Подведение итогов курса',
      '7. Онлайн-тренинг "Трудоустройство в IT"',
      '8. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '9 500 ₽',
  },
  {
    key: Courses.java,
    categoryName: CategoryNames.programming,
    courseName: 'Java разработчик',
    discountPrice: '90 000 ₽',
    oldPrice: '115 000 ₽',
    perMonth: '5000',
    oldPerMonth: '6388',
    img: IconsNames.cs_java,
    link: PATHS.java,
    educationFrom: EducationForm.online,
    duration: '7 месяцев',
    closestStart: 'Ближайший старт - 19 ноября',
    youWillLearn: 'Научим программировать на Java за 7 месяцев.',
    futureKnowledge: 'За время курса ты научишься писать интересные алгоритмы, работать с коллекциями, файлами и др., напишешь парочку многопоточных приложений, начнешь работать с базами данных, изучишь самые популярные фреймворки - Spring и Hibernate и разработаешь web-приложение.',
    // technologies: [Technologies.java, Technologies.pyCharm],
    futureSalaryTitle: 'Зарплата Java разработчика',
    salary: '100 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '240 академических часов',
    lessons: [
      '1. Введение в Java',
      '2. ООП в Java. Строки. Исключения и ошибки.',
      '3. Коллекции',
      '4. Особенности работы с Java 8, 9-12',
      '5. Потоки Java. Работа с Интеренетом',
      '6. Основы работы с XML и JSON, Основы Servlet API',
      '7. Основы HTML, CSS',
      '8. S.O.L.I.D. Паттерны проектирования',
      '9. Базы данных',
      '10. Spring Core, Spring MVC, Spring Boot',
      '11. Java Persistence API, Spring Security, Hibernate',
      '12. DevOps',
      '13. Закрепление пройденного материала. Консультации по дипломному проекту',
      '14. Онлайн-тренинг "Трудоустройство в IT"',
      '15. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '11 500 ₽',
  },
  {
    key: Courses.person,
    categoryName: CategoryNames.gameDev,
    courseName: '3D художник по персонажам',
    discountPrice: '45 000 ₽',
    oldPrice: '60 000 ₽',
    perMonth: '2500',
    oldPerMonth: '3333',
    img: IconsNames.cs_person,
    link: PATHS.person,
    educationFrom: EducationForm.online,
    duration: '6 месяцев',
    closestStart: 'Ближайший старт - 19 ноября',
    youWillLearn: 'Стань востребованным 3D художником c нуля за 6 месяцев',
    futureKnowledge: 'Специализация на создании персонажей включает множество навыков из смежных профессий и зачастую превосходит их по сложности. Например, персонажник способен создать окружение, а вот специалист по окружению не всегда справится с персонажем, ведь здесь требуется глубокое понимание анатомии, работы с волосами и одеждой.',
    // technologies: [Technologies.java, Technologies.pyCharm],
    futureSalaryTitle: 'Зарплата 3D художника по персонажам',
    salary: '80 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '200 академических часов',
    lessons: [
      '1. 3D художник по персонажам',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '6 000 ₽',
  },
  {
    key: Courses.system_analyst,
    categoryName: CategoryNames.analysis,
    courseName: 'Системный аналитик',
    discountPrice: '40 000 ₽',
    oldPrice: '50 000 ₽',
    perMonth: '2222',
    oldPerMonth: '2777',
    img: IconsNames.cs_system,
    link: PATHS.system_analyst,
    educationFrom: EducationForm.online,
    duration: '2 месяца',
    closestStart: 'Ближайшие старты - 20 ноября, 28 декабря',
    youWillLearn: 'Научим системному анализу с 0 до трудоустройства за 2 месяца',
    futureKnowledge: 'Системный аналитик - это специалист, работающий с представителями бизнеса и командой разработки IT-решения. Системный аналитик, опираясь на требования к системе, вместе с разработчиками определяет наиболее эффективное техническое решение и документирует его, начиная от архитектуры и заканчивая интерфейсами взаимодействия.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата системеного аналитика',
    salary: '50 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '60 академических часов',
    lessons: [
      '1. Описание прототипов интерфейсов',
      '2. Интеграция систем и документирование API',
      '3. Введение в архитектуру',
      '4. Моделирование процессов',
      '5. SQL для анализа данных',
      '6. Основы современной разработки ПО',
      '7. Защита дипломных проектов',
    ],
    benefitIcon: IconsNames.benefit10,
    discountOffer: '5 000 ₽',
  },
  {
    key: Courses.qa_csharp,
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на C#',
    discountPrice: '70 000 ₽',
    oldPrice: '85 000 ₽',
    perMonth: '3888',
    oldPerMonth: '4722',
    img: IconsNames.cs_qa_csharp,
    link: PATHS.qa_csharp,
    educationFrom: EducationForm.online,
    duration: '4,5 месяцев',
    closestStart: 'Ближайший старт - 21 ноября',
    youWillLearn: 'Научим автоматизированному тестированию на C# за 4,5 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API. Познакомишься с современными тестовыми фреймворками, научишься создавать тестовую документацию, анализировать результаты тестов и составлять отчеты по ним.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата тестировщика на C#',
    salary: '60 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '140 академических часов',
    lessons: [
      '1. Введение в .NET и C#. Репозитории',
      '2. Введение в ООП. Обобщения. Исключения. Коллекции',
      '3. Введение в тестирование ПО',
      '4. Введение в автоматизацию тестирования',
      '5. Selenium WebDriver',
      '6. MStest, Xunit, Nunit. Репортинг',
      '7. GOF Patterns и Best Practices. Continuous integration.',
      '8. Подходы к построению тестов',
      '9. Автоматизация REST API. Работа с базой данных',
      '10. Консультация по дипломному проекту. Тестовое задание',
      '11. Онлайн-тренинг "Трудоустройство в IT"',
      '12. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '8 500 ₽',
  },
  {
    key: Courses.fullstack_csharp,
    categoryName: CategoryNames.programming,
    courseName: 'C# (.NET) разработчик',
    discountPrice: '70 000 ₽',
    oldPrice: '100 000 ₽',
    perMonth: '3888',
    oldPerMonth: '5555',
    img: IconsNames.cs_csharp,
    link: PATHS.fullstack_csharp,
    educationFrom: EducationForm.online,
    duration: '6 месяцев',
    closestStart: 'Ближайший старт - 25 ноября',
    youWillLearn: 'Обучим программированию на C# за 6 месяцев',
    futureKnowledge: 'ASP.NET разработчик создаёт приложения и игры на языке программирования C# на платформе .NET, которую поддерживает Microsoft.    На курсе ты изучишь язык программирования C# от основ до самых используемых паттернов, платформу .NET Framework и среду разработки Visual Studio. Разберешься с базами данных и ASP.NET Core и MVC, сможешь писать асинхронный, многопоточный и протестированный код, и разработаешь свое первое web-приложение.',
    // technologies: [Technologies.java, Technologies.flask],
    futureSalaryTitle: 'Зарплата FullStack C# разработчика',
    salary: '80 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '172 академических часа',
    lessons: [
      '1. Введение в .NET и C#',
      '2. ООП. Обобщения. Исключения. Сериализация.',
      '3. Основы ASP.NET. Контроллеры. Модели и представления.',
      '4. Базы данных и SQL',
      '5. Основы ORM - Entity Framework',
      '6. ASP.NET WebAPI. Асинхронность. MVC. Многопоточность',
      '7. Закрепление пройденного материала',
      '8. DevOps',
      '9. Консультации по дипломному проекту',
      '10. Онлайн-тренинг "Трудоустройство в IT"',
      '11. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit30,
    discountOffer: '10 000 ₽',
  },
  {
    key: Courses.go,
    categoryName: CategoryNames.programming,
    courseName: 'GO разработчик',
    discountPrice: '85 000 ₽',
    oldPrice: '110 000 ₽',
    perMonth: '4722',
    oldPerMonth: '6111',
    img: IconsNames.cs_go,
    link: PATHS.go,
    educationFrom: EducationForm.online,
    duration: '4,5 месяца',
    closestStart: 'Ближайший старт - 25 ноября',
    youWillLearn: 'Научим писать production-ready код на одном из самых востребованных языков web-разработки за 4,5 месяца.',
    futureKnowledge: 'Go - стремительно развивающийся и набирающий большую популярность язык разработки бэкенда для web-серверных приложений.    Созданный в Google, язык уже снискал широкую популярность за свой молниеносный билд, потрясающий перформанс, простой синтаксис, сильное и активное комьюнити и широко используется как в состоявшихся гигантах на международной IT-арене , так и прекрасно подходит для стартапов любого уровня. Google, Intel, Microsoft, Avito, Ozon, - проще назвать компании, которые не работают с Golang, чем перечислять те, кто с этим языком тесно связан.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата GO разработчика',
    salary: '60 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '136 академических часов',
    lessons: [
      '1. Введение в Go',
      '2. Методы, интерфейсы, конфигурации',
      '3. Работа с сетью и кодирование данных',
      '4. Многопоточность и работа с API',
      '5. Базы данных',
      '6. Углубленное изучение Go',
      '7. DevOps',
      '8. Ретроспектива',
      '9. Защита дипломных проектов',
      '10. Онлайн-тренинг "Трудоустройство в IT"',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '11 000 ₽',
  },
  {
    key: Courses.devops,
    categoryName: CategoryNames.programming,
    courseName: 'DevOps инженер',
    discountPrice: '95 000 ₽',
    oldPrice: '125 000 ₽',
    perMonth: '5277',
    oldPerMonth: '6944',
    img: IconsNames.cs_devops,
    link: PATHS.devops,
    educationFrom: EducationForm.online,
    duration: '7,5 месяцев',
    closestStart: 'Ближайшие старты - 25 ноября, 19 декабря',
    youWillLearn: 'С нуля до трудоустройства: научим безопасно разворачивать, мониторить и масштабировать приложения в облаке за 7,5 месяцев.',
    futureKnowledge: 'DevOps - это методология разработки программного обеспечения, задача которой наладить взаимодействии разработчиков, тестировщиков и operations отдела в компании.  Следование методологии DevOps позволяет компаниям ускорить процесс выпуска качественного программного продукта. На курсе ты познакомишься и освоишь основные инструменты, практики и методики DevOps для дальнейшего применения их в работе.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата DevOps инженера',
    salary: '85 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '244 академических часа',
    lessons: [
      '1. Введение в DevOps и операционные системы',
      '2. Компьютерные сети и системы виртуализации',
      '3. Unix утилиты',
      '4. Репозитории',
      '5. Bash/Shell',
      '6. Вебсервера. Базы данных',
      '7. Управление конфигурацией',
      '8. Docker и микросервисная архитектура',
      '9. Введение в Python',
      '10. Continuous integration',
      '11. Облачные технологии. AWS',
      '12. Инфраструктура как код (IaC)',
      '13. Kubernetes',
      '14. Мониторинг и логирование',
      '15. Методологии Agile/Scrum/DevOps',
      '16. Консультации по дипломному проекту',
      '17. Онлайн-тренинг "Трудоустройство в IT"',
      '18. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit30,
    discountOffer: '12 500 ₽',
  },
  {
    key: Courses.web,
    categoryName: CategoryNames.programming,
    courseName: 'Web разработчик',
    discountPrice: '95 000 ₽',
    oldPrice: '125 000 ₽',
    perMonth: '5277',
    oldPerMonth: '6944',
    img: IconsNames.cs_web,
    link: PATHS.web,
    educationFrom: EducationForm.online,
    duration: '10 месяцев',
    closestStart: 'Ближайший старт - 26 ноября',
    youWillLearn: 'С нуля до трудоустройства: HTML, CSS и JavaScript, PHP за 10 месяцев.',
    futureKnowledge: 'Курсы Веб-разработчик с нуля позволяют начинающим разработчикам создавать интерфейсы, с которыми взаимодействуют пользователи. Он знает HTML, CSS, PHP и JavaScript – все для того, чтобы стать Full-Stack Веб-разработчиком.    На курсе ты научишься верстать адаптивные и кроссбраузерные приложения и сайты, разрабатывать серверную часть приложения и работать с базами данных. На курсе ты напишешь свое первое приложение на JavaScript и PHP, изучишь популярные сегодня библиотеку Vue.js и фреймворк Laravel, разработаешь web-приложение, используя эти инструменты.  Обладая такими знаниями, ты будешь иметь большое преимущество при трудоустройстве.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Web разработчика',
    salary: '50 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '284 академических часа',
    lessons: [
      '1. Введение в HTML и CSS',
      '2. Введение в мобильные и веб-технологии',
      '3. Основы работы с PHP',
      '4. Введение в ООП',
      '5. Введение в базы данных и основы языка SQL',
      '6. PDO, PSR, Composer',
      '7. Разработка MVC. Основы REST API',
      '8. Laravel',
      '9. Введение в JavaScript',
      '10. Vue.js',
      '11. Laravel mix',
      '12. Подведение итогов курса',
      '13. DevOps',
      '14. Онлайн-тренинг "Трудоустройство в IT"',
      '15. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit30,
    discountOffer: '12 500 ₽',
  },
  {
    key: Courses.data_engineer,
    categoryName: CategoryNames.programming,
    courseName: 'Инженер данных',
    discountPrice: '75 000 ₽',
    oldPrice: '100 000 ₽',
    perMonth: '4166',
    oldPerMonth: '5555',
    img: IconsNames.cs_data_engineer,
    link: PATHS.data_engineer,
    educationFrom: EducationForm.online,
    duration: '6 месяцев',
    closestStart: 'Ближайший старт - 27 ноября',
    youWillLearn: 'Стань востребованным Data-инженером c нуля через 6 месяцев',
    futureKnowledge: 'Курс Data Engineering в нашей школе предоставит вам возможность стать специалистом в области обработки данных за короткий срок всего в 6 месяцев. Программа разработана опытными профессионалами, активно работающими в индустрии, что обеспечит вам не только теоретические знания, но и практические навыки, необходимые для успешной карьеры в данной области.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Инженера данных',
    salary: '120 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '200 академических часов',
    lessons: [
      '1. Введение. Роли и задачи Data Engineer',
      '2. Введение в Python',
      '3. SQL',
      '4. Моделирование данных',
      '5. Интеграция данных и ETL процессы',
      '6. Введение в Apache Airflow',
      '7. Практические проекты',
      '8. Подготовка к дипломному проекту',
      '9. Онлайн-занятие «Поиск работы в IT»',
      '10. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '10 000 ₽',
  },
  {
    key: Courses.one_c,
    categoryName: CategoryNames.programming,
    courseName: '1С разработчик',
    discountPrice: '65 000 ₽',
    oldPrice: '80 000 ₽',
    perMonth: '3611',
    oldPerMonth: '4444',
    img: IconsNames.cs_one_c,
    link: PATHS.one_c,
    educationFrom: EducationForm.online,
    duration: '3,5 месяца',
    closestStart: 'Ближайшие старты - 27 ноября, 28 декабря',
    youWillLearn: 'Подготовим к сдаче экзамена по 1С специалист c нуля за 3,5 месяца',
    futureKnowledge: 'Онлайн-обучение работе с программами от «1С» в школе TeachMeSkills решает сразу две задачи. Во-первых, ты осваиваешь платформу и язык, напоминающий Visual Basic с конструкциями и комментариями из C++. Во-вторых, менторы готовят тебя к сдаче аттестаций «1С:Специалист» и «1С:Специалист-консультант», разбирая типовые задания, прикладные решения или методы конфигурирования продуктов.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата разработчика на 1С',
    salary: '55 000 ₽',
    programText: 'Программу всех занятий по 1С в нашей школе программирования разрабатывают и ведут практикующие IT специалисты.',
    academyHours: '112 академических часов',
    lessons: [
      '1. Установка, настройка, основные функции',
      '2. Работа с объектами конфигурации',
      '3. Конструктор запросов',
      '4. Бухгалтерский учёт',
      '5. Использование бизнес-процессов',
      '6. Работа с данными',
      '7. API (12) и дополнительные конструкторы',
      '8. СКД',
      '9. Ретроспектива',
      '10. Онлайн-занятие «Поиск работы в IT»',
      '11. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '8 000 ₽',
  },
  {
    key: Courses.flutter,
    categoryName: CategoryNames.programming,
    courseName: 'Мобильный разработчик на Flutter',
    discountPrice: '55 000 ₽',
    oldPrice: '70 000 ₽',
    perMonth: '3055',
    oldPerMonth: '3888',
    img: IconsNames.cs_flutter,
    link: PATHS.flutter,
    educationFrom: EducationForm.online,
    duration: '3 месяца',
    closestStart: 'Ближайший старт - 27 ноября',
    youWillLearn: 'С нуля до трудоустройства: научим разрабатывать приложения на Flutter за 3 месяца.',
    futureKnowledge: 'На курсе ты изучишь язык программирования Dart, научишься проектировать универсальные интерфейсы для Android и iOS, научишься проектировать архитектуру приложения, работать с сетью и данными. После курса в твоем портфолио уже будет три полностью готовых приложения.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Мобильного разработчика на Flutter',
    salary: '50 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '104 академических часа',
    lessons: [
      '1. Установка окружения. Язык программирования Dart',
      '2. Flutter. Основы',
      '3. Разработка 1-го приложения Quiz app',
      '4. Сеть и архитектура',
      '5. Разработка 2-го приложения TODO',
      '6. Хранилище. Создание плагинов',
      '7. Защита дипломного проекта',
      '8. Онлайн-тренинг "Трудоустройство в IT"',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '7 000 ₽',
  },
  {
    key: Courses.designer,
    categoryName: CategoryNames.design,
    courseName: 'Графический дизайн',
    discountPrice: '70 000 ₽',
    oldPrice: '90 000 ₽',
    perMonth: '3888',
    oldPerMonth: '5000',
    img: IconsNames.cs_designer,
    link: PATHS.designer,
    educationFrom: EducationForm.online,
    duration: '5 месяцев',
    closestStart: 'Ближайшие старты - 27 ноября, 23 декабря',
    youWillLearn: 'Научим создавать дизайн-макеты любой сложности, айдентику и  фирменный стиль за 5 месяцев.',
    futureKnowledge: 'В ходе курса ты познакомишься с увлекательной и наглядной теорией дизайна которая научит тебя создавать крутые проекты основываясь на опыте профессионалов и основных фишках зрительного восприятия.   Во время практических занятий ты научишься работать с научишься создавать айдентику и фирменный стиль и узнаешь как работать с брендами. Разберешься в нюансах создания дизайна упаковки и поймешь как правильно упаковывать проекты.   Начнешь разбираться в печатных эффектах и адаптировать любые дизайны.   Благодаря курсу ты получишь уникальные знания, научишься работать с основными инструментами для создания дизайна, и освоишь интересную профессию способную вызывать эмоции.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата графического дизайнера',
    salary: '70 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '138 академических часов',
    lessons: [
      '1. Основы профессии',
      '2. Теория',
      '3. Инструментарий',
      '4. Коммуникационный дизайн',
      '5. Начало работы',
      '6. Онлайн-занятие «Поиск работы в IT»',
      '7. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit20,
    discountOffer: '9 000 ₽',
  },
  {
    key: Courses.android,
    categoryName: CategoryNames.programming,
    courseName: 'Android разработчик',
    discountPrice: '70 000 ₽',
    oldPrice: '90 000 ₽',
    perMonth: '3888',
    oldPerMonth: '5000',
    img: IconsNames.cs_android,
    link: PATHS.android,
    educationFrom: EducationForm.online,
    duration: '6 месяцев',
    closestStart: 'Ближайший старт - 27 ноября',
    youWillLearn: 'Научим разрабатывать приложения под Android за 6 месяцев.',
    futureKnowledge: 'На курсе Android разработчик ты изучишь языки программирования Java и Kotlin, научишься проектировать и анимировать интерфейсы, работать с данными и сетью. Разработаешь свое Android приложение, узнаешь способы его монетизации и тестирования.',
    // technologies: [Technologies.pyCharm, Technologies.python],
    futureSalaryTitle: 'Зарплата Android разработчика',
    salary: '60 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '176 академических часов',
    lessons: [
      '1. Введение в Java',
      '2. ООП. Исключения. Коллекции.',
      '3. Stream API. Определение потоков Java',
      '4. Введение в Kotlin и Android',
      '5. Работа со списками. UI Components. Архитектура приложения',
      '6. Основные принципы программирования. Инъекция зависимостей. Kotlin Coroutines.',
      '7. Network. Data Storage.',
      '8. View',
      '9. Продвинутая инъекция зависимостей. Асинхронная работа в Android',
      '10. Reactive Streams – RxJava2. GoogleMaps',
      '11. Android Testing. Google Play Market',
      '12. Подведение итогов курса',
      '13. Онлайн-тренинг "Трудоустройство в IT"',
      '14. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit20,
    discountOffer: '9 000 ₽',
  },
  {
    key: Courses.uxui,
    categoryName: CategoryNames.design,
    courseName: 'UX/UI дизайнер',
    discountPrice: '70 000 ₽',
    oldPrice: '85 000 ₽',
    perMonth: '3888',
    oldPerMonth: '4722',
    img: IconsNames.cs_uxui,
    link: PATHS.uxui,
    educationFrom: EducationForm.online,
    duration: '4 месяца',
    closestStart: 'Ближайшие старты - 28 ноября, 23 декабря',
    youWillLearn: 'С нуля до трудоустройства: проектирование интерфейсов мобильных приложений и web-сайтов за 4 месяца.',
    futureKnowledge: 'В ходе курса ты спроектируешь множество интерфейсов и будешь готов к решению большинства задач, стоящих перед UX/UI дизайнером. Ты освоишь Figma и другие современные инструменты.    Среди практических заданий будет проектирование интерфейсов iOS или Android приложений, а также web-сайтов разной сложности. Это поможет составить по результатам курса сильное портфолио. А благодаря знаниям UX-research ты сможешь начать проектировать интерфейсы, которые действительно полюбят пользователи.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата UX/UI дизайнера',
    salary: '65 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '168 академических часов',
    lessons: [
      '1. Основы дизайна и профессии',
      '2. Сбор и анализ данных',
      '3. Понятие продукта',
      '4. Figma',
      '5. Интерфейсы',
      '6. Элементы графического интерфейса',
      '7. Дизайн мобильных приложений',
      '8. Портфолио и дальнейшее развитие',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '8 500 ₽',
  },
  {
    key: Courses.frontend,
    categoryName: CategoryNames.programming,
    courseName: 'Front End разработчик',
    discountPrice: '80 000 ₽',
    oldPrice: '110 000 ₽',
    perMonth: '4444',
    oldPerMonth: '6111',
    img: IconsNames.cs_fe,
    link: PATHS.frontend,
    educationFrom: EducationForm.online,
    duration: '7 месяцев',
    closestStart: 'Ближайшие старты - 28 ноября, 18 декабря',
    youWillLearn: 'С нуля до трудоустройства: HTML, CSS и JavaScript за 7 месяцев.',
    futureKnowledge: 'Курсы Front End с нуля позволяют начинающим разработчикам создавать интерфейсы, с которыми взаимодействуют пользователи. Он знает HTML, CSS и JavaScript – язык, который работает на всех платформах и единственный язык программирования для браузеров.',
    // technologies: [
    //   Technologies.javaScript,
    //   Technologies.css,
    //   Technologies.html,
    //   Technologies.gitLab,
    // ],
    futureSalaryTitle: 'Зарплата Front End разработчика',
    salary: '75 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '228 академических часов',
    lessons: [
      '1. Введение в HTML и CSS. Модель Flexbox. Позиционирование',
      '2. Продвинутая работа с CSS. Адаптивная вёрстка и автоматизация',
      '3. JavaScript. Базовый уровень. Функции, объекты, массивы',
      '4. JavaScript. События. Асинхронность. Прототипы и классы',
      '5. Введение TypeScript',
      '6. React',
      '7. Redux',
      '8. Основы работы с API',
      '9. Основы тестирования. Webpack',
      '10. Онлайн-тренинг "Трудоустройство в IT"',
      '11. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit30,
    discountOffer: '11 000 ₽',
  },
  {
    key: Courses.python,
    categoryName: CategoryNames.programming,
    courseName: 'Python разработчик',
    discountPrice: '80 000 ₽',
    oldPrice: '100 000 ₽',
    perMonth: '4444',
    oldPerMonth: '5555',
    img: IconsNames.cs_python,
    link: PATHS.python,
    educationFrom: EducationForm.online,
    duration: '5,5 месяцев',
    closestStart: 'Ближайшие старты - 28 ноября, 23 декабря',
    youWillLearn: 'Разработка web-приложений на Python за 5,5 месяцев с нуля и до трудоустройства',
    futureKnowledge: 'Язык программирования Python является одним из лучших для обучения с нуля. Простой синтаксис + высокая скорость разработки – это все, что нужно для создания широкого спектра web-приложений. Курс Python с нуля в IT-школе TeachMeSkills поможет тебе изучить экосистему Python, научиться разрабатывать приложения на фреймворке Django, а также работать с серверами и базами данных.',
    // technologies: [Technologies.java, Technologies.fastApi],
    futureSalaryTitle: 'Зарплата Python разработчика',
    salary: '95 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '204 академических часа',
    lessons: [
      '1. Введение в алгоритмизацию и экосистему Python. Основы.',
      '2. ООП. Базы данных и SQL. Flask',
      '3. Django. Представления. Шаблоны. Django REST.',
      '4. JWT Auth. Документирование API. Celery',
      '5. Асинхронный Python. Aiohttp',
      '6. DevOps',
      '7. Введение в РМ инструменты',
      '8. Подготовка к дипломному проекту',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit20,
    discountOffer: '10 000 ₽',
  },
  {
    key: Courses.ds,
    categoryName: CategoryNames.analysis,
    courseName: 'Data Scientist',
    discountPrice: '100 000 ₽',
    oldPrice: '125 000 ₽',
    perMonth: '5555',
    oldPerMonth: '6944',
    img: IconsNames.cs_ds,
    link: PATHS.ds,
    educationFrom: EducationForm.online,
    duration: '7,5 месяцев',
    closestStart: 'Ближайший старт - 28 ноября',
    youWillLearn: 'С нуля до трудоустройства: научим Data Science за 7,5 месяцев.',
    futureKnowledge: 'С развитием технологий и повсеместным использованием гаджетов все легче становится собирать различные данные о том, что происходит в мире — от наших кликов в интернет-пространстве до структуры развития человеческого генома. Для того, чтобы уметь обращаться с этими данными, и существует Data Science.  Какую песню предложить тебе для прослушивания? Как определить, где пешеход, а где встречная машина во время движения? Как спрогнозировать изменение цен на криптовалюту в будущем?    На этом курсе ты сможешь понять, как работают все вышеперечисленные алгоритмы, а также научишься строить свои предиктивные модели, используя фреймворки TensorFlow и PyTorch.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Data Scientist',
    salary: '100 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '240 академических часов',
    lessons: [
      '1. Введение Machine Learning',
      '2. Введение в Python',
      '3. Базы данных и SQL',
      '4. Базовый математический уровень для Data Science',
      '5. Введение в нейронные сети',
      '6. Нейронные сети и Deep Learning',
      '7. Sequence Models. Natural Language Processing',
      '8. Основы Times Series Prediction. Решение задач СV, NLP, Time Series prediction',
      '9. Основы Computer Science и web',
      '10. Основы работы в облачных сервисах',
      '11. Закрепление итогового материала и выбор дипломного проекта',
      '12. Подготовка к техническому собеседованию',
      '13. Онлайн-тренинг "Трудоустройство в IT"',
      '14. Защита дипломных проектов',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '12 500 ₽',
  },
  {
    key: Courses.qa_java,
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на Java',
    discountPrice: '70 000 ₽',
    oldPrice: '85 000 ₽',
    perMonth: '3888',
    oldPerMonth: '4722',
    img: IconsNames.cs_qa_java,
    link: PATHS.qa_java,
    educationFrom: EducationForm.online,
    duration: '5 месяцев',
    closestStart: 'Ближайший старт - 29 ноября',
    youWillLearn: 'Научим автоматизированному тестированию на Java за 5 месяцев.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата тестировщика на Java',
    salary: '70 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '152 академических часа',
    lessons: [
      '1. Введение в Java',
      '2. Введение в тестирование ПО',
      '3. Введение в автоматизацию тестирования',
      '4. Selenium WebDriver. JUnit, TestNG. Maven',
      '5. GOF Patterns и Best Practices. Continuous integration – CircleCI',
      '6. Автоматизация REST API. Работа с базой данных',
      '7. Консультация по дипломному проекту',
      '8. Интервью',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '8 500 ₽',
  },
  {
      key: Courses.business_intelligence,
      categoryName: CategoryNames.analysis,
      courseName: 'Business Intelligence (BI) разработчик',
      discountPrice: '60 000 ₽',
      oldPrice: '75 000 ₽',
      perMonth: '3333',
      oldPerMonth: '4166',
      img: IconsNames.cs_bi,
      link: PATHS.business_intelligence,
      educationFrom: EducationForm.online,
      duration: '4 месяца',
      closestStart: 'Ближайший старт - 2 декабря',
      youWillLearn: 'С нуля до трудоустройства: инструменты, практики и методики для старта в карьере Business Intelligence за 4 месяца.',
      futureKnowledge: 'Business Intelligence - это инструмент, который позволяет решать следующие вопросы:  · сбор данных  · хранение данных  · анализ данных  · визуализация данных  · принятие решений на основе данных    На курсе ты освоишь методики и технологии для подготовки и управления данными, узнаешь всё о хранилищах данных, получишь ценные знания и опыт при построении современной интерактивной отчетности.',
      // technologies: [Technologies.python],
      futureSalaryTitle: 'Зарплата BI разработчика',
      salary: '60 000 ₽',
      programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
      academyHours: '92 академических часа',
      lessons: [
        '1. Знакомство с BI',
        '2. SQL',
        '3. Работа с базами данных',
        '4. Создание ETL решения',
        '5. Визуализация данных',
        '6. Тренинг "Трудоустройство в IT"',
        '7. Защита дипломного проекта',
      ],
      benefitIcon: IconsNames.benefit15,
      discountOffer: '7 500 ₽',
    },
  {
      key: Courses.business_analyst,
      categoryName: CategoryNames.analysis,
      courseName: 'Бизнес-аналитик в IT',
      discountPrice: '75 000 ₽',
      oldPrice: '95 000 ₽',
      perMonth: '4166',
      oldPerMonth: '5277',
      img: IconsNames.cs_ba,
      link: PATHS.business_analyst,
      educationFrom: EducationForm.online,
      duration: '4 месяца',
      closestStart: 'Ближайший старт - 10 декабря',
      youWillLearn: 'С нуля до трудоустройства: бизнес-анализ за 4 месяца.',
      futureKnowledge: 'Курсы Business Analyst в IT позволят вам стать начинающим бизнес-аналитиком в различных компаниях. Бизнес-аналитик в IT - это специалист, работающий с представителями бизнеса и командой разработки IT-решения. Используя различные техники анализа требований, бизнес-аналитик изучает проблему, возможность для бизнеса и предоставляет спецификацию требований в разработку.    Для общения с различными группами заинтересованных лиц и решению сложных и трудоемких задач бизнес-аналитик обладает коммуникативными навыками, пониманию технических основ IT-продуктов, хорошим английским и структурным мышлением.',
      // technologies: [Technologies.python],
      futureSalaryTitle: 'Зарплата бизнес-аналитика в IT',
      salary: '80 000 ₽',
      programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
      academyHours: '132 академических часа',
      lessons: [
        '1. Введение в бизнес-анализ',
        '2. Основы программного обеспечения и IT-инфраструктуры',
        '3. Коммуникации. Бизнес-требования. Бизнес-процессы',
        '4. Разработка требований и документирование',
        '5. Моделирование и прототипирование',
        '6. Продакт-менеджмент',
        '7. Работа над дипломным проектом',
        '8. Онлайн-тренинг «Трудоустройство в IT»',
        '9. Защита дипломного проекта',
      ],
      benefitIcon: IconsNames.benefit20,
      discountOffer: '9 500 ₽',
    },
    {
      key: Courses.ml,
      categoryName: CategoryNames.programming,
      courseName: 'Machine Learning',
      discountPrice: '100 000 ₽',
      oldPrice: '130 000 ₽',
      perMonth: '5555',
      oldPerMonth: '7222',
      img: IconsNames.cs_ml,
      link: PATHS.ml,
      educationFrom: EducationForm.online,
      duration: '7,5 месяцев',
      closestStart: 'Ближайший старт - 12 декабря',
      youWillLearn: 'Научим Machine Learning с нуля за 7,5 месяцев.',
      futureKnowledge: 'ML-специалист, который занимается обучением модели, передаёт данные и пытается объяснить, что хочет получить на выходе. У алгоритма нет заданного ответа, к которому нужно прийти, он знает лишь, как построить модель, отвечающую на поставленный вопрос. Цель машинного обучения — научить модель саму находить решение.',
      // technologies: [Technologies.python],
      futureSalaryTitle: 'Зарплата специалиста по Machine Learning',
      salary: '80 000 ₽',
      programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
      academyHours: '248 академических часов',
      lessons: [
        '1. Введение в Machine Learning и Deep Learning',
        '2. Компьютерное зрение',
        '3. Обработка текста',
        '4. Развертывание моделей машинного обучения в производственной среде',
        '5. Проведение пробного технического собеседования',
        '6. Онлайн-тренинг «Трудоустройство в IT»',
        '7. Защита дипломного проекта',
      ],
      benefitIcon: IconsNames.benefit30,
      discountOffer: '13 000 ₽',
    },
  {
    key: Courses.ios,
    categoryName: CategoryNames.programming,
    courseName: 'iOS разработчик',
    discountPrice: '85 000 ₽',
    oldPrice: '105 000 ₽',
    perMonth: '4722',
    oldPerMonth: '5833',
    img: IconsNames.cs_ios,
    link: PATHS.ios,
    educationFrom: EducationForm.online,
    duration: '5 месяцев',
    closestStart: 'Ближайший старт - 16 декабря',
    youWillLearn: 'Научим разработке мобильных приложений на Swift за 5 месяцев',
    futureKnowledge: 'На курсе тебя ждет обучение Swift с нуля, ты научишься проектировать и анимировать интерфейсы, разработаешь свое iOS приложение, узнаешь способы его монетизации и опубликуешь в App Store.',
    // technologies: [Technologies.java, Technologies.flask],
    futureSalaryTitle: 'Зарплата iOS разработчика',
    salary: '75 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '204 академических часа',
    lessons: [
      '1. Введение в XCode и SWIFT',
      '2. ООП. Storyboard',
      '3. Базовая навигация',
      '4. Анимация. Solid-принципы',
      '5. Attributed Strings. Отображение ошибок. WebKit',
      '6. Работа с датами и управление памятью',
      '7. Многопоточность и работа с API',
      '8. Хранение данных, Core Data',
      '9. Работа с базой данных',
      '10. Основы безопасности',
      '11. Паттерны',
      '12. Процесс разработки и локализация',
      '13. Cocoapods. Геолокация. FireBase',
      '14. Монетизация',
      '15. SwiftUI. Combine.',
      '16. VIPER / CleanSwift',
      '17. UnitTesting',
      '18. Публикация приложения в Appstore',
      '19. Ретроспектива',
      '20. Онлайн-тренинг "Трудоустройство в IT"',
      '21. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit20,
    discountOffer: '10 500 ₽',
  },
  {
    key: Courses.unity,
    categoryName: CategoryNames.gameDev,
    courseName: 'Разработчик игр на Unity',
    discountPrice: '87 000 ₽',
    oldPrice: '102 000 ₽',
    perMonth: '4833',
    oldPerMonth: '5666',
    img: IconsNames.cs_unity,
    link: PATHS.unity,
    educationFrom: EducationForm.online,
    duration: '6,5 месяцев',
    closestStart: 'Ближайший старт - 18 декабря',
    youWillLearn: 'Научим разработке игр на Unity за 6,5 месяцев.',
    futureKnowledge: 'Unity - это современный и мощный игровой движок, позволяющий делать игры любого уровня.  Unity-разработчик создаёт игры и приложения почти под все игровые платформы.  На курсе тебя ждет не только изучение движка Unity, но и языка программирования C# с нуля.  К тому же ты научишься создавать игры от стадии идеи до загрузки в Google Play/AppStore, используя данные инструменты и возможности.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата разработчика игр на Unity',
    salary: '55 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '196 академических часов',
    lessons: [
      '1. O Unity и его возможностях. Начало проекта Shooter.',
      '2. С# и игра Hangman',
      '3. ООП. Создание игры HOG (Hidden Object Game)',
      '4. Runner с генераций уровня',
      '5. Геймдизайн. Теоретический минимум по Computer Science',
      '6. Игра Idle',
      '7. Платформер',
      '8. Match 3. Крестики-нолики в AR',
      '9. Игра пасьянс (косынка)',
      '10. Внедрение улучшений в Shooter 3D',
      '11. Закрепление пройденного материала',
      '12. Онлайн-тренинг "Трудоустройство в IT"',
      '13. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '10 200 ₽',
  },
  {
      key: Courses.motion,
      categoryName: CategoryNames.design,
      courseName: 'Motion дизайнер',
      discountPrice: '85 000 ₽',
      oldPrice: '115 000 ₽',
      perMonth: '4722',
      oldPerMonth: '6388',
      img: IconsNames.cs_motion,
      link: PATHS.motion,
      educationFrom: EducationForm.online,
      duration: '5 месяцев',
      closestStart: 'Ближайший старт - 19 декабря',
      youWillLearn: 'Научим созданию 2D анимации и основам 3D графики за 5 месяцев.',
      futureKnowledge: 'К концу курса ты научишься создавать простую и сложную 2D анимацию. Изучишь программу After Effects, научишься работать с шейпами, кейфреймами и тд. Узнаешь основы 3D графики, визуальных эффектов. Сможешь самостоятельно анимировать персонажей, логотипы, инфографику и типографику. Начнешь выстраивать композицию, научишься работать с цветом, шрифтами, светом, узнаешь основы монтажа. А также поработаешь в таких программных продуктах, как Adobe AE, Media Encoder, Trapcode и другие.',
      // technologies: [Technologies.python],
      futureSalaryTitle: 'Зарплата Motion дизайнера',
      salary: '60 000 ₽',
      programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
      academyHours: '164 академических часа',
      lessons: [
        '1. О Motion дизайне',
        '2. Работа в программе After Effects',
        '3. Работа с другими программами Adobe',
        '4. Способы работы в 3D',
        '5. Скрипты и плагины',
        '6. Персонажная анимация',
        '7. Работа с видео',
        '8. Практика',
        '9. Портфолио и развитие',
        '10. Онлайн-тренинг "Трудоустройство в IT"',
        '11. Защита дипломного проекта',
      ],
      benefitIcon: IconsNames.benefit30,
      discountOffer: '11 500 ₽',
    },
    {
        key: Courses.hr,
        categoryName: CategoryNames.management,
        courseName: 'HR-Generalist',
        discountPrice: '45 000 ₽',
        oldPrice: '55 000 ₽',
        perMonth: '2500',
        oldPerMonth: '3055',
        img: IconsNames.cs_hr,
        link: PATHS.hr,
        educationFrom: EducationForm.online,
        duration: '3 месяца',
        closestStart: 'Ближайший старт - 23 декабря',
        youWillLearn: 'Научим главным инструментам, практикам и методикам для старта в карьере HR-Generalist за 3 месяца.',
        futureKnowledge: 'За время курса ты разберешься во всех функциональных направлениях HR. Узнаешь как устроен мир IT, основные профессии и технологии.    Ты научишься, как и где находить кандидатов, как проводить с ними успешные интервью и формировать лучшую команду.Пройдешь через все этапы жизненного цикла сотрудника. Разберешься, как правильно собирать и анализировать информацию, необходимую для работы HR, выстраивать процессы, влиять на HR-бренд и корпоративную культуру. HR-Generalist - это возможность пройти все этапы от рекрутинга до HR-директора в любой компании.',
        // technologies: [Technologies.python],
        futureSalaryTitle: 'Зарплата HR-Generalist',
        salary: '50 000 ₽',
        programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
        academyHours: '80 академических часов',
        lessons: [
          '1. Об HR Generalist и IT в целом и в частности',
          '2. Рекрутинг',
          '3. Онбординг и мотивация',
          '4. Способы управление эффективностью: KPI, OKR',
          '5. Инструменты управления персоналом',
          '6. HR-бренд и корпоративная культура',
          '7. Проведение HR-аудита и создание HR-стратегии',
          '8. Кроссфункциональные знания в работе HR',
          '9. Тренинг "Трудоустройство в IT"',
          '10. Защита дипломных проектов',
        ],
        benefitIcon: IconsNames.benefit10,
        discountOffer: '5 500 ₽',
      },
      {
        key: Courses.qa_python,
        categoryName: CategoryNames.testing,
        courseName: 'Автоматизированное тестирование на Python',
        discountPrice: '65 000 ₽',
          oldPrice: '80 000 ₽',
          perMonth: '3611',
          oldPerMonth: '4444',
          img: IconsNames.cs_qa_python,
          link: PATHS.qa_python,
          educationFrom: EducationForm.online,
          duration: '4 месяца',
          closestStart: 'Ближайший старт - 23 декабря',
          youWillLearn: 'Научим автоматизированному тестированию на Python за 4 месяца.',
          futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
          // technologies: [Technologies.python],
          futureSalaryTitle: 'Зарплата тестировщика на Python',
          salary: '70 000 ₽',
          programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
          academyHours: '132 академических часа',
          lessons: [
            '1. Введение в алгоритмизацию и экосистему Python',
            '2. Основы тестирования ПО',
            '3. Введение в Linux',
            '4. Модульное тестирование',
            '5. Введение в автоматизацию тестирования. Selenium WebDriver',
            '6. REST API. Базы данных. Docker',
            '7. Консультация по дипломному проекту и тестовое задание',
            '8. Онлайн-тренинг "Трудоустройство в IT"',
            '9. Защита дипломного проекта',
          ],
          benefitIcon: IconsNames.benefit15,
          discountOffer: '8 000 ₽',
        },
     {
    key: Courses.product_analyst,
    categoryName: CategoryNames.analysis,
    courseName: 'Продуктовый аналитик в IT',
    discountPrice: '75 000 ₽',
    oldPrice: '95 000 ₽',
    perMonth: '4166',
    oldPerMonth: '5277',
    img: IconsNames.cs_pa,
    link: PATHS.product_analyst,
    educationFrom: EducationForm.online,
    duration: '5 месяцев',
    closestStart: 'Ближайший старт - 23 декабря',
    youWillLearn: 'Научим с нуля продуктовому анализу в IT за 5 месяцев.',
    futureKnowledge: 'Курс Product Analyst позволит тебе стать начинающим продуктовым аналитиком в data-driven компаниях — то есть тех, которые ориентируются в решениях на big data и аналитику данных.    Продуктовый аналитик в IT — это специалист, который собирает, обрабатывает, изучает и интерпретирует данные, проводит А/B-тесты, строит модели и проверяет, как пользователи и клиенты реагируют на нововведения, выдвигает гипотезы и проводит аналитические исследования, направленные на выявление узких мест проекта и поиск точек роста. Главная задача такого специалиста — превратить цифры и графики в понятные выводы, чтобы руководитель мог принимать решения.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Продуктовый аналитик в IT',
    salary: '90 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '144 академических часа',
    lessons: [
      '1. Введение в сферу IT',
      '2. Продуктовая аналитика',
      '3. Google таблицы, Excel',
      '4. Python',
      '5. Статистика',
      '6. SQL',
      '7. Визуализация в Tableau',
      '8. Ретроспектива. Консультации',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломных проектов',
    ],
    benefitIcon: IconsNames.benefit20,
    discountOffer: '9 500 ₽',
  },
        {
    key: Courses.manual_qa,
    categoryName: CategoryNames.testing,
    courseName: 'Тестирование веб и мобильных приложений',
    discountPrice: '55 000 ₽',
    oldPrice: '70 000 ₽',
    perMonth: '3055',
    oldPerMonth: '3888',
    img: IconsNames.cs_manual_qa,
    link: PATHS.manual_qa,
    educationFrom: EducationForm.online,
    duration: '4 месяца',
    closestStart: 'Ближайший старт - 27 декабря',
    youWillLearn: 'Научим ручному тестирование веб и мобильных приложений за 4 месяца. ',
    futureKnowledge: 'Manual QA Engineer проводит анализ приложений и дальнейшее тестирование, доводя продукт до конечного пользователя в стабильном и исправном состоянии.    На курсе ты научишься работать с требованиями, разрабатывать тестовую документацию, узнаешь о различных подходах и техниках тестирования в условиях различных методологий разработки ПО. А самое главное - ты узнаешь, как тестировать реальные веб и мобильные приложения (iOS/Android)! Мы поделимся своим опытом и наработками, которые тебе помогут войти в IT с легкой ноги.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата тестировщика веб и мобильных приложений',
    salary: '55 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '104 академических часа',
    lessons: [
      '1. Введение в тестирование приложений',
      '2. Клиент-серверная архитектура. API. Базы данных.',
      '3. Тестирование веб-приложений',
      '4. Мобильные приложения (Android/iOS): сравнения, выбор устройств для мобильного тестирования',
      '5. Работа с Android-приложением',
      '6. Работа с iOS-приложением',
      '7. Тестирование мобильных приложений: проверки, сложности, особенности выкладки, инструменты',
      '8. Подведение итогов курса',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '7 000 ₽',
  },
  {
    key: Courses.cyber,
    categoryName: CategoryNames.programming,
    courseName: 'Кибербезопасность',
    discountPrice: '85 000 ₽',
    oldPrice: '100 000 ₽',
    perMonth: '4722',
    oldPerMonth: '5555',
    img: IconsNames.cs_cyber,
    link: PATHS.cyber,
    educationFrom: EducationForm.online,
    duration: '5,5 месяцев',
    closestStart: 'Ближайший старт - 21 января',
    youWillLearn: 'Научим кибербезопасности за 5,5 месяцев.',
    futureKnowledge: 'На курсе ты узнаешь, какие средства защиты используются на предприятии, как проводятся атаки на инфраструктуру. Поймёшь, какие бывают типы угроз, вирусов и т.д. Проведёшь свой первый CTF. Побываешь как со стороны Blue team, так и со стороны Red team. Станешь востребованным специалистом по Кибербезопасности, который выявляет угрозы информационной безопасности и риски потери данных.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата Продуктовый аналитик в IT',
    salary: '100 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '168 академических часов',
    lessons: [
      '1. Введение в кибербез и основы виртуализации',
      '2. Сети, маршрутизация, криптография',
      '3. Типы и этапы кибератак',
      '4. Безопасность ОС',
      '5. Защита инфраструктур',
      '6. Основные виды СЗИ и аудит',
      '7. VA и Event management',
      '8. Форензика и языки скриптинга',
      '9. Кодинг и уязвимости',
      '10. Reverse engineering',
      '11. Основы Information gathering',
      '12. Pentest',
      '13. CTF',
      '14. Развитие в кибербезе',
      '15. Ретроспектива',
      '16. Онлайн-тренинг "Трудоустройство в IT"',
      '17. Защита дипломных проектов',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '10 000 ₽',
  },
  {
    key: Courses.game_dev,
    categoryName: CategoryNames.gameDev,
    courseName: '3D моделирование в GameDev',
    discountPrice: '100 000 ₽',
    oldPrice: '125 000 ₽',
    perMonth: '5555',
    oldPerMonth: '6944',
    img: IconsNames.cs_3d,
    link: PATHS.game_dev,
    educationFrom: EducationForm.online,
    duration: '8 месяцев',
    closestStart: 'Ближайший старт - 29 января',
    youWillLearn: 'Стань востребованным 3D дизайнером за 8 месяцев.',
    futureKnowledge: '3D-моделирование — это создание трехмерных компьютерных изображений и графики.  На нашем курсе ты ознакомишься с актуальным пайплайном по созданию 3D моделей крупных ААА студий как для игровой, так и для киноиндустрии.    По окончании обучения ты сможешь развиваться в следующих направлениях: 3D generalist, Character Artist, Props Artist, Environment Artist, Vehicle Artist, VFX Designer.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата 3D Artist',
    salary: '70 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '296 академических часов',
    lessons: [
      '1. Знакомство с Autodesk Maya',
      '2. Полигональное моделирование по референсу',
      '3. Subdiv в Autodesk Maya',
      '4. Проверочная работа по пройденному модулю',
      '5. Знакомство с ZBrush. Кисти. Скульптинг',
      '6. Нововведения в ZBrush. HardSurface',
      '7. Проверочная работа',
      '8. Ретопология модели в Autodesk Maya',
      '9. Развертка в Autodesk Maya',
      '10. Знакомство с Marmoset Toolbag',
      '11. Знакомство с Adobe Photoshop и Adobe Painter',
      '12. Стилизованное текстурирование в Painter',
      '13. Проверочная работа по текстурированию и запечке моделей',
      '14. Рендеринг моделей',
      '15. Знакомство с Unreal Engine 4 - 5 и Bridge',
      '16. Знакомство с Marvelous Designer',
      '17. Введение в Blender',
      '18. Подготовка дипломных работ. Знакомство с Artstation',
      '19. Знакомство со SpeedTree',
      '20. Онлайн-тренинг "Трудоустройство в IT"',
      '21. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '12 500 ₽',
  },
  {
    key: Courses.unreal_engine,
    categoryName: CategoryNames.gameDev,
    courseName: 'Разработчик игр на Unreal Engine 5',
    discountPrice: '110 000 ₽',
    oldPrice: '135 000 ₽',
    perMonth: '6111',
    oldPerMonth: '7500',
    img: IconsNames.cs_unreal_engine,
    link: PATHS.unreal_engine,
    educationFrom: EducationForm.online,
    duration: '12 месяцев',
    closestStart: 'Ближайший старт - 30 января',
    youWillLearn: 'С нуля до трудоустройства: научим разрабатывать игры за 12 месяцев.',
    futureKnowledge: 'Unreal Engine - это не просто игровой движок, а инструмент, позволяющий работать в разных направлениях: игры, архитектурная визуализация, виртуал продакшн, automotive.    По окончанию этого курса сможешь претендовать на разные роли в разработке и рендеринге игровых или не игровых продуктов.    Ты научишься не только создавать игры, но и пройдешь весь пайплайн разработки, познакомишься с разными ролями при разработке (геймдизайн, левел дизайн и окружение, VFX, интеграции).',
    // technologies: [Technologies.java, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.gitLab, Technologies.python, Technologies.flask, Technologies.javaScript, Technologies.html, Technologies.css],
    futureSalaryTitle: 'Зарплата разработчика игр на Unreal Engine 5',
    salary: '65 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '280 академических часов',
    lessons: [
      '1. Введение в разработку игр',
      '2. Старт работы над первой игрой Twin Stick Shooter',
      '3. Разработка искусственного интеллекта',
      '4. Level Design',
      '5. Работа над визуальными эффектами',
      '6. Финальный этап проекта',
      '7. Портирование игры Twin Stick Shooter на Android',
      '8. Базовые понятия С++',
      '9. С++ в Unreal Engine 5',
      '10. Старт второго проекта. Работа над основными механиками',
      '11. Разработка AI',
      '12. Работа над Level Design',
      '13. Интерфейс и визуальные эффекты',
      '14. Финальные этапы проекта',
      '15. Прикладные знания',
      '16. Экстра',
      '17. Ретроспектива',
      '18. Онлайн-тренинг "Трудоустройство в IT"',
      '19. Защита дипломного проекта',
    ],
    benefitIcon: IconsNames.benefit25,
    discountOffer: '13 500 ₽',
  },
  {
    key: Courses.qa_js,
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на JavaScript',
    discountPrice: '65 000 ₽',
    oldPrice: '80 000 ₽',
    perMonth: '3611',
    oldPerMonth: '4444',
    img: IconsNames.cs_qa_js,
    link: PATHS.qa_js,
    educationFrom: EducationForm.online,
    duration: '4,5 месяца',
    closestStart: 'Ближайший старт - 29 ноября',
    youWillLearn: 'Научим автоматизированному тестированию на JavaScript за 4,5 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата тестировщика на JavaScript',
    salary: '70 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '124 академических часа',
    lessons: [
      '1. Введение в Typescript',
      '2. Введение в тестирование ПО',
      '3. Введение в автоматизацию тестирования',
      '4. Тестирование API. Selenium',
      '5. PageObjects. WebDriverIO. Playwright. Cypress',
      '6. Работа с базой данных. CI/CD',
      '7. Консультация по дипломному проекту',
      '8. Интервью',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',  
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '8 000 ₽',
  },
  {
    key: Courses.sre,
    categoryName: CategoryNames.programming,
    courseName: 'SRE инженер',
    discountPrice: '100 000 ₽',
    oldPrice: '130 000 ₽',
    perMonth: '5555',
    oldPerMonth: '7222',
    img: IconsNames.cs_sre,
    link: PATHS.sre,
    educationFrom: EducationForm.online,
    duration: '8,5 месяцев',
    closestStart: 'Ближайший старт - 12 февраля',
    youWillLearn: 'Стань востребованным SRE инженером c нуля через 8,5 месяцев.',
    futureKnowledge: 'После окончания курса SRE инженер ты станешь специалистом, способным обеспечить надежность и стабильность IT-инфраструктуры. Ты освоишь ключевые принципы SRE и научишься управлять метриками надежности (SLA, SLO), внедряя отказоустойчивые системы, балансировщики нагрузки и кластеры для максимальной доступности сервисов.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата SRE инженера',
    salary: '120 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '248 академических часа',
    lessons: [
      '1. Введение в SRE',
      '2. Сети и Linux',
      '3. Отказоустойчивость',
      '4. Kubernetes',
      '5. Автоматизация и кодинг',
      '6. Observability',
      '7. Cloud',
      '8. SecOps',
      '9. Практики SRE',
      '10. Chaos engineering',
      '11. Заключение',
    ],
    benefitIcon: IconsNames.benefit30,
    discountOffer: '13 000 ₽',
  },  
  {
    key: Courses.game_design,
    categoryName: CategoryNames.gameDev,
    courseName: 'Геймдизайн',
    discountPrice: '65 000 ₽',
    oldPrice: '80 000 ₽',
    perMonth: '3611',
    oldPerMonth: '4444',
    img: IconsNames.cs_gamedesign,
    link: PATHS.game_design,
    educationFrom: EducationForm.online,
    duration: '3,5 месяца',
    closestStart: 'Ближайший старт - 25 февраля',
    youWillLearn: 'Научим с нуля созданию дизайна игр за 3,5 месяца.',
    futureKnowledge: 'К концу курса ты научишься создавать правильную и понятную документацию в соответствии с требованиями и принципами геймдизайна. Поймёшь, как работает производство игр, пройдешь полный цикл разработки игрового продукта. Освоишь базис Unreal Engine 5, некоторые его основные принципы, технологии и инструменты. Поймешь, как работают маркетинговые стратегии, рассчитывается баланс и монетизация проекта.    По итогу обучения ты разработаешь полный геймдизайн-документ по своему проекту. А далее ты создашь собственный проект в Unreal Engine 5, реализуешь Core loop или какую-то другую основную механику из гейм-дизайн документа.',
    // technologies: [Technologies.python],
    futureSalaryTitle: 'Зарплата геймдизайнера',
    salary: '50 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '100 академических часов',
    lessons: [
      '1. Основы геймдизайна',
      '2. Изучение Unreal Engine 5',
      '3. Маркетинг, тестирование, подготовка к дипломной работе',
      '4. Онлайн-тренинг "Трудоустройство в IT"',
      '5. Защита дипломных проектов',
    ],
    benefitIcon: IconsNames.benefit15,
    discountOffer: '8 000 ₽',
  },  
];
